
import { Config } from 'client-website-ts-library/services';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { Component, Vue } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class ConfidentialBuyerRegistration extends Vue {
}
